<template>
  <div>
    <b-table-custom :table-data="dataObjectList"
                    :table-columns="columns"
                    :is-data-loading="isTableDataLoading"
    >
      <template #button_event>
        <loading-button
            label="Thêm mới"
            icon="fas fa-plus"
            class="btn btn-primary"
            size="sm"
            @click="openModal('modal-create-new')"
        />
      </template>
      <template #cell(key)="{data}">
        <strong class="text-primary">{{ data['value'] }}</strong>
      </template>
      <template #cell(value)="{data}">
        <strong>{{ shortContent(data['value']) }}</strong>
      </template>
      <template #menu="{item}">
        <template v-for="(menuItem, index) in menuButtonList">
          <b-dropdown-divider v-if="menuItem['type'] === 'divider'"/>
          <b-dropdown-item v-else-if="menuItem['visible'] === undefined || menuItem['visible'](item)"
                           :disabled="menuItem['disabled']"
                           @click="openModal(menuItem['name'], item)"
          >
            <font-awesome-icon :icon="menuItem['icon']" :class="`text-${menuItem['color']}`" />
            <span>{{ menuItem['label'] }}</span>
          </b-dropdown-item>
        </template>
      </template>
    </b-table-custom>

    <custom-modal id="setting-modal"
                  ref="setting-modal"
                  :title="currentModal.title"
                  :variant="currentModal.variant"
                  :ok-title="currentModal.okTitle"
                  :cancel-title="currentModal.cancelTitle"
                  :is-manual-close="currentModal.isManualClose"
                  @ok="currentModal.okAction"
    >
      <b-card-text v-if="modalName === 'modal-create-new'">
        <custom-input v-model="newObjectDetail['key']" label="Key" />
        <b-form-group>
          <label>Value</label>
          <b-textarea v-model="newObjectDetail['value']" label="Value" />
        </b-form-group>
        <b-form-group>
          <label>Description</label>
          <b-textarea v-model="newObjectDetail['description']" label="Description" />
        </b-form-group>
      </b-card-text>
      <b-card-text v-else-if="modalName === 'modal-edit'">
        <custom-input v-model="dataObjectSelected['key']" label="Key" />
        <b-form-group>
          <label>Value</label>
          <b-textarea v-model="dataObjectSelected['value']" label="Value" />
        </b-form-group>
        <b-form-group>
          <label>Description</label>
          <b-textarea v-model="dataObjectSelected['description']" label="Description" />
        </b-form-group>
        <switch-button v-model="dataObjectSelected['is_active']"
                       true-label="Active"
                       false-label="Inactive"
        />
      </b-card-text>
      <b-card-text v-else-if="modalName === 'modal-delete'">
        <p>Bạn có chắc chắn muốn xoá setting này không?</p>
      </b-card-text>
    </custom-modal>
  </div>
</template>

<script>

import InputHelper from "@/mixins/utils/inputHelper";
import DataRequester from "@/mixins/utils/dataRequester";
import DataObjectList from "@/mixins/utils/dataObjectList";

import { mainStore } from "@/store/pinia/main.store"

import BTableCustom from "@/views/components/table/BTableCustom.vue";
import LoadingButton from "@/views/components/LoadingButton.vue";
import CustomModal from "@/views/components/CustomModal.vue";
import CustomInput from "@/views/components/form/CustomInput.vue";
import SwitchButton from "@/views/components/form/SwitchButton.vue";
import settingService from "@/mixins/utils/axios/setting.service";

export default {
  name: "Setting",
  mixins: [InputHelper, DataRequester, DataObjectList],
  components: {
    BTableCustom,
    LoadingButton,
    CustomModal,
    CustomInput,
    SwitchButton
  },
  computed: {
    currentModal() {
      switch(this.modalName) {
        case 'modal-create-new':
          return {
            title: 'Thêm mới setting',
            variant: 'primary',
            okTitle: 'Thêm mới',
            cancelTitle: 'Đóng',
            isManualClose: true,
            okAction: this.insertSetting
          }
          case 'modal-edit':
          return {
            title: 'Cập nhật setting',
            variant: 'primary',
            okTitle: 'Cập nhật',
            cancelTitle: 'Đóng',
            isManualClose: true,
            okAction: this.updateSetting
          }
          case 'modal-delete':
          return {
            title: 'Xoá setting',
            variant: 'danger',
            okTitle: 'Xoá',
            cancelTitle: 'Đóng',
            isManualClose: true,
            okAction: this.deleteSetting
          }
        default:
          return {
            title: '',
            variant: '',
            okTitle: '',
            cancelTitle: '',
            okAction: () => {}
          }
      }
    }
  },
  data() {
    return {
      columns: [
        {
          key: 'key',
          label: 'Key'
        },
        {
          key: 'value',
          label: 'Value'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'is_active',
          label: 'Is Active'
        },
        {
          key: 'menu',
          label: 'Menu'
        }
      ],
      isTableDataLoading: false,
      modalName: '',
      newObjectDetail: {},

      menuButtonList: [
        {
          name: 'modal-edit',
          label: 'Cập nhật',
          icon: 'fas fa-edit',
          color: 'primary'
        },
        {
          name: 'modal-delete',
          label: 'Xoá',
          icon: 'fas fa-trash',
          color: 'danger'
        }
      ]
    }
  },
  created() {
    this.getSettingList()
  },
  methods: {
    openModal (modalName, data) {
      this.modalName = modalName
      this.$refs['setting-modal'].show()
      this.dataObjectSelected = {...data}

    },
    closeModal () {
      this.$refs['setting-modal'].hide()
    },

    getSettingList() {
      let self = this
      this.isTableDataLoading = true
      this.runApi(settingService.getSettingList(), function (result) {
        self.dataObjectList = result.data
      }, undefined, function () {
        self.isTableDataLoading = false
      })
    },

    insertSetting () {
      let self = this
      this.runApi(settingService.insertSetting(this.newObjectDetail), function (result) {
        self.getSettingList()
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
    updateSetting () {
      let self = this
      const rowData = this.dataObjectSelected
      this.runApi(settingService.updateSetting(rowData), function (result) {
        if (rowData['key'] === 'lev_balance') {
          mainStore().setLevBalance(rowData['value'])
        }
        self.getSettingList()
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
    deleteSetting () {
      let self = this
      this.runApi(settingService.deleteSetting(this.dataObjectSelected.id), function (result) {
        self.dataObjectList = self.dataObjectList.filter(item => item['id'] !== self.dataObjectSelected['id'])
      }, undefined, function () {
        self.closeModal()
      }, true)
    }
  }
}
</script>
